<gel-messaging
  [shown]="isShown"
  [loadingMessages]="isLoadingThread"
  [loadingConverstions]="isLoading"
  [addEnabled]="addNew"
  [(message)]="message"
  [conversations]="conversations"
  [sending]="isSending"
  [sendDisabled]="sendDisabled"
  [selectedConversation]="selectedConversation"
  [mentionListFn]="onSearch.bind(this)"
  [maxMessageLength]="200"
  (close)="closeMessages()"
  (addConversation)="onAdd()"
  (selectConversation)="onSelect($event)"
  (deleteConversation)="onDelete($event)"
  (sendMessage)="onSend($event)"
>
  <gel-messaging-add-form>
    <div class="gel__layout-flex" [style.gap.px]="16">
      <div [style.flex]="1">
        <label class="gel__text-semibold mb0.5">
          Subject
          <span class="gel__text-bold text-danger caption-config">*</span>
        </label><br />
        <input
          maxlength="50"
          nbInput
          fullWidth
          [(ngModel)]="addForm.subject"
        />
      </div>

      <div [style.flex]="1">
        <label class="gel__text-semibold mb0.5">
          Send to
          <span
            *ngIf="!this.taggedUserIds.length"
            class="gel__text-bold text-danger caption-config"
          >*</span>
        </label><br />
        <nb-select
          [selected]="addForm.recipients"
          multiple
          [withOptionSearch]="true"
          (searchInput)="onSearchRecipients($event)"
          placeholder="Select"
          (selectedChange)="onChangeRecipients($event)"
        >

          <nb-option *ngIf="isSearchingRecipients; else showRecipient">
            <div class="gel__layout-flex items-center">
              <span
                class="chip-spinner"
                [nbSpinner]="true"
                nbSpinnerStatus="primary"
                nbSpinnerSize="tiny"
              ></span>
              <span class="text-blue">Searching...</span>
            </div>
          </nb-option>
          <ng-template #showRecipient>
            <nb-option
              *ngFor="let recipient of recipients"
              [value]="recipient.id"
            >
              {{ recipient.name }}
            </nb-option>
          </ng-template>
        </nb-select>
      </div>
      <div [style.flex]="1">
      </div>

    </div>
  </gel-messaging-add-form>

  <gel-messaging-footer>
    <button
      nbButton
      outline
      class="px3"
      (click)="closeMessages()">
      Close
    </button>
  </gel-messaging-footer>
</gel-messaging>

<span
  id="required-message-indicator"
  class="gel__text-bold text-danger caption-config"
>*</span>
<app-prompt-modal 
  [dataModel]="deleteModal" 
  (cancel)="onCloseModal($event)"
></app-prompt-modal>