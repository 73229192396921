/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MessageNotification } from '../models/messaging/message-notification.model';
import { MessageNotificationsPayload } from '../models/messaging/message-notifications-payload.model';
import { MessageResponse } from '../models/messaging/message-response.model';
import { MessageThread } from '../models/messaging/message-thread.model';
import { Message } from '../models/messaging/message.model';
import { MessagesPayload } from '../models/messaging/messages-payload.model';
import { NewMessagePayload } from '../models/messaging/new-message-payload.model';
import { NewMessageResponse } from '../models/messaging/new-message-response.model';
import { ReplyMessagePayload } from '../models/messaging/reply-message-payload.model';
import { SystemNotification } from '../models/messaging/system-notification.model';
import { Result } from '../models/result.model';
import { SearchResult } from '../models/search-result.model';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  url = `${environment.apiBaseUrl}${environment.apiPath}`;

  messages: Message[] = [];

  private _isOpen = false;
  get isOpen(): boolean {
    return this._isOpen;
  }

  get unseenMessagesCount(): number {
    const count = this.messages.filter(
      (m) => !m.isSeen && m.type === 'Message'
    ).length;
    return count;
  }

  get unseenSystemNotificationsCount(): number {
    const count = this.messages.filter(
      (m) => !m.isSeen && m.type === 'SystemNotification'
    ).length;
    return count;
  }

  get totalNotificationsCount(): number {
    return this.unseenMessagesCount + this.unseenSystemNotificationsCount;
  }

  get systemNotifications(): SystemNotification[] {
    // const rawNotifications = this.messages.filter(
    //   (m) => m.type === 'SystemNotification'
    // );
    const notifications: SystemNotification[] = [];
    // for (const { messageId, subject, isSeen } of rawNotifications) {
    //   const words = drop(
    //     subject.split(' ').filter((w) => w.trim()),
    //     2
    //   );
    //   const sentence = words.join(' ');
    //   const index = sentence.indexOf('has been');
    //   const campaignName = sentence.slice(0, index - 1);
    //   const message = sentence.replace(campaignName, '').trim();
    //   const id = messageId;
    //   const notification: SystemNotification = {
    //     id,
    //     campaignName,
    //     message,
    //     isSeen,
    //   };
    //   notifications.push(notification);
    // }
    return notifications;
  }

  constructor(private http: HttpClient) {}

  open(): void {
    setTimeout(() => (this._isOpen = true));
  }

  close(): void {
    this._isOpen = false;
  }

  sendNewMessage(
    payload: NewMessagePayload
  ): Promise<Result<NewMessageResponse>> {
    const url = `${this.url}/Message`;
    return this.http.post<Result<NewMessageResponse>>(url, payload).toPromise();
  }

  sendReplyMessage(
    payload: ReplyMessagePayload
  ): Promise<Result<NewMessageResponse>> {
    const url = `${this.url}/Message`;
    return this.http.post<Result<NewMessageResponse>>(url, payload).toPromise();
  }

  getMessageNotifications(
    payload: MessageNotificationsPayload
  ): Promise<Result<SearchResult<MessageNotification>>> {
    const url = `${this.url}/Message/search`;
    return this.http
      .post<Result<SearchResult<MessageNotification>>>(url, payload)
      .toPromise();
  }

  getMessageThreads(
    payload: MessagesPayload
  ): Promise<Result<SearchResult<Message>>> {
    const url = `${this.url}/Message/search`;
    return this.http.post<Result<SearchResult<Message>>>(url, payload).toPromise();
  }

  getMessageThreadById(id: string): Promise<Result<MessageResponse>> {
    const url = `${this.url}/Message/${id}`;
    return this.http.get<Result<MessageResponse>>(url).toPromise();
  }

  unsubscribeFromMessage(
    messageId: string
  ): Promise<Result<Record<string, never>>> {
    const url = `${this.url}/Message/${messageId}/notification/OptOut`;
    return this.http.put<Result<Record<string, never>>>(url, null).toPromise();
  }

  getThreadMessagesById(messageId: string): Promise<Result<MessageThread>> {
    const url = `${this.url}/Message/${messageId}`;
    return this.http.get<Result<MessageThread>>(url).toPromise();
  }

  setMessageAsSeen(messageId: string): Promise<Result<Record<string, never>>> {
    const url = `${this.url}/Message/${messageId}/notification`;
    const payload = { isSeen: true };
    return this.http
      .put<Result<Record<string, never>>>(url, payload)
      .toPromise();
  }
}
