import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-error-forbidden',
  templateUrl: './error-forbidden.component.html',
  styleUrls: ['./error-forbidden.component.css'],
})
export class ErrorForbiddenComponent implements OnInit {
  loginUrl = environment.portalLink;
  countdown = 10;

  ngOnInit(): void {
    interval(1000).subscribe(() => {
      this.countdown -= 1;
      if (this.countdown <= 0) {
        location.href = environment.portalLink;
      }
    });
  }
}
