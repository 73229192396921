import { Filter } from "../filter.model";
import { FilterGroupPayload } from "../payloads/filter-group-payload.model";

export class UserSearchModel {
  page: number = 1;
  pageSize: number = 10;
  orderBy: string = "Email";
  direction: string = 'desc';
  filterGroups: Array<FilterGroupPayload> = [];
  searchTerm?: string;
  constructor(searchTerm: string) {
    const filter = {
      filters: [{
        Column: "Email",
        Operation: "==",
        SearchFor: searchTerm
      }]
    };
    this.filterGroups.push(filter);
  }
}