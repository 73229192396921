import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from './core/service/auth.service';
import * as GelEditor from '@engagis.gel/editor';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    { provide: Window, useValue: window }
  ]
})
export class AppComponent implements OnInit {
  title = 'ezesuite-impress-frontend';
  withMenu = true;

  constructor(
    private router: Router,
    private location: Location,
    private authService: AuthService,
    private window: Window
  ) {
    this.router.events.subscribe(() => {
      this.withMenu = true;

      if (
        this.location.path().match(/\/preview/g)
        || this.location.path().match(/\/widget-details/g)
        || this.location.path().match(/\/unauthorized/g)
        || this.location.path().match(/\/session-expired/g)
        || this.location.path().match(/\/error-request/g)
        || this.location.path().match(/\/error-forbidden/g)
      ) {
        this.withMenu = false;
      }
    });
    // eslint-disable-next-line
    this.window['GelEditor'] = GelEditor;
  }

  async ngOnInit(): Promise<void> {
    const isLogout = (localStorage.getItem('isLogout') === 'true');

    if(isLogout) {
      this.onLogout();
    } else {
      await this.authService.login();
    }
    window.addEventListener('storage', () => {
      this.onLogout();
    });
  }

  onLogout(): void {
    const isLogout = (localStorage.getItem('isLogout') === 'true');

    if(isLogout) {
      this.authService.logout();
      localStorage.removeItem('isLogout');
    }
  }
}
