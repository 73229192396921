/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserInfo } from 'src/app/shared/models/user-info.model';
import { UserService } from 'src/app/data/data-ei/service/user.service';
import { UserSearchModel } from 'src/app/data/data-ei/models/users/user-search.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthModel } from 'src/app/shared/services/modules/auth/auth.model';
import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly ACCESS_USER_INFO = 'userAccessinfo';
  private readonly access_token = 'access_token';
  private tokenHelper = new JwtHelperService();

  get UserInfo(): UserInfo {
    let user: UserInfo = {
      name: '',
      description: '',
      email: ''
    };
    const info = sessionStorage.getItem('userAccessinfo');
    const parseInfo = JSON.parse(info) as UserInfo;
    if (parseInfo) {
      user = parseInfo;
    }

    return user;
  }

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private router: Router,
    @Inject(APP_BASE_HREF) public baseHref: string
  ) {}

  async login(): Promise<void> {
    const currentLocation = new URL(window.location.href);
    const stateFromLocation = currentLocation.searchParams.get('state');
    const token = this.getSessionToken();
    if (stateFromLocation) {
      if (window.sessionStorage.getItem('state') !== stateFromLocation) {
        throw Error('Probable session hijacking attack!');
      } else {
        if (token && this.isAccessTokenValid(token)) {
          await this.setUserInfo(token);
        } else {
          this.getToken().subscribe({
            next: async (val: string) => {
              await this.setUserInfo(val);
              let prevUrl = (sessionStorage.getItem('prevUrl') === '/')
                ? 'dashboard'
                : sessionStorage.getItem('prevUrl');

              // this section will remove the token parameter
              const isTokenParamExist = prevUrl.indexOf('?token');
              if(isTokenParamExist !== -1) {
                prevUrl = prevUrl.substring(0, isTokenParamExist);
              }

              // sessionStorage.removeItem('prevUrl');
              void this.router.navigateByUrl(prevUrl);
            },
          });
        }
      }
    } else {
      if (token) {
        await this.setUserInfo(token);
      } else {
        this.redirectToLogin();
      }
    }
  }

  logout(isLogout = ''): void {
    this.clearStorage();

    if(isLogout !== '') {
      localStorage.setItem('isLogout', isLogout);
    }
    window.location.href = environment.portalLink;
  }

  isAccessTokenValid(token: string): boolean {
    try {
      return !this.tokenHelper.isTokenExpired(token);
    } catch {
      return false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getToken() {
    const currentLocation = new URL(window.location.href);
    const authorizationCode = currentLocation.searchParams.get('code');
    const stateFromLocation = currentLocation.searchParams.get('state');
    const initialCodeVerifier = window.sessionStorage.getItem('code_verifier');

    // This is a good place for checking the state too
    if (window.sessionStorage.getItem('state') !== stateFromLocation) {
      throw Error('Probable session hijacking attack!');
    }

    const queryParams = {
      client_id: environment.client_id,
      grant_type: 'authorizationcode',
      state: stateFromLocation,
      code: authorizationCode,
      code_verifier: initialCodeVerifier,
    };

    return this.http
      .post<AuthModel>(environment.loginUrl, queryParams, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
        },
      })
      .pipe(
        map((data) => {
          sessionStorage.setItem(this.access_token, JSON.stringify(data));
          return data.access_token;
        })
      );
  }

  getRefreshToken(token: string): Observable<AuthModel> {
    const options = {
      headers: new HttpHeaders()
        .set('refresh_token', token)
        .set('Ocp-Apim-Subscription-Key', environment.azureSubscriptionKey),
      params: new HttpParams().set('grant_type', 'refresh_token'),
    };
    return this.http.post<AuthModel>(environment.loginUrl, {}, options);
  }

  redirectToLogin(): void {
    window.location.href = this.buildLoginUrl();
  }

  buildLoginUrl(): string {
    // generate random string for state
    const state = this.getRandomString(48);

    // generate random string for code verifier
    const codeVerifier = this.getRandomString(128);

    // save state and code verifier in session storage
    this.saveStateAndVerifier(state, codeVerifier);

    const params = [
      'client_id=' + environment.client_id,
      'redirect_uri=' + window.location.origin + `${this.baseHref}auth`,
      'response_type=code',
      'state=' + state,
      'code_challenge=' + this.generateCodeChallenge(codeVerifier),
      'code_challenge_method=S256',
    ];
    return environment.portalLink + '?' + params.join('&');
  }

  getSessionToken(): string | null {
    const accessToken = sessionStorage.getItem(this.access_token);
    if (accessToken) {
      const accessTokenObj = JSON.parse(accessToken) as AuthModel;
      return accessTokenObj.access_token;
    }
    return null;
  }

  async setUserInfo(token: string): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const USER = this.tokenHelper.decodeToken(token);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const info = JSON.parse(JSON.stringify(USER));

    // eslint-disable-next-line
    const email = info['ezesuite:user:email'] as string;
    const { result } = await this.userService.getUsers(
      new UserSearchModel(email)
    );

    let param: UserInfo = {
      email,
    };

    if (result.searchResult.length) {
      const res = result.searchResult[0];
      param = {
        ...param,
        name: `${res.firstName} ${res.lastName}`,
        description: res.roles.map((c) => c.name).join(', ')
      };
    }

    sessionStorage.setItem(this.ACCESS_USER_INFO, JSON.stringify(param));
  }

  private saveStateAndVerifier(state: string, code_verifier: string): void {
    const storage = window.sessionStorage;
    // storage.clear();
    storage.setItem('state', state);
    storage.setItem('code_verifier', code_verifier);
  }

  private getRandomString(length: number) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  private generateCodeChallenge(codeVerifier: string) {
    // apply SHA256 in code verifier
    const codeVerifierHash = CryptoJS.SHA256(codeVerifier).toString(
      CryptoJS.enc.Base64
    );

    return codeVerifierHash;
  }

  private clearStorage(): void {
    // localStorage.clear();
    sessionStorage.clear();
  }
}
