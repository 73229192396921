import { Component, OnInit } from '@angular/core';
// import { interval } from 'rxjs/internal/observable/interval';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error-request',
  templateUrl: './error-request.component.html',
  styleUrls: ['./error-request.component.css'],
})
export class ErrorRequestComponent implements OnInit {
  countdown = 10;
  page: string;

  counterInterval = null;

  localRoute: string;
  routeId: string;
  routeQuery = {
    errorStatus: '',
    errorText: '',
    previousRoute: '',
  };

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.routeQuery = JSON.parse(
      this.route.snapshot.queryParamMap.get('data')
    ) as {
      errorStatus: string;
      errorText: string;
      previousRoute: string;
    };
    const routeSplit = this.routeQuery.previousRoute.split('/');
    this.localRoute = routeSplit[1];
    this.routeId = routeSplit.length > 2 ? routeSplit[2] : '';
  }

  errorMessages(): string {
    return `Error ${this.routeQuery.errorStatus} - ${this.routeQuery.errorText}.`;
  }

  get getPageName(): string {
    let value = '';
    if(this.localRoute !== '') {
      value = this.localRoute.replace('-', ' ');
    }

    if(!value.includes('dashboard')) {
      value += 's';
    }

    return value;
  }

  routeTo(): void {
    const pageRoute =
      this.routeId !== ''
        ? this.localRoute + '/' + this.routeId
        : this.localRoute;
    void this.router.navigate([pageRoute]);
  }
}
