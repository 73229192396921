import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PromptModalModel } from 'src/app/shared/models/modals/prompt-modal.model';

@Component({
  selector: 'app-prompt-modal',
  templateUrl: './prompt-modal.component.html',
  styleUrls: ['./prompt-modal.component.css']
})
export class PromptModalComponent {
  @Input() dataModel: PromptModalModel<void>;
  @Output() cancel: EventEmitter<string> = new EventEmitter<string>();

  onAccept(): void {
    this.onCancel();
    this.cancel.emit(this.dataModel.yesText);
  }

  onCancel(): void {
    this.dataModel.show = false;
    this.cancel.emit('');
  }

  getFooterPosition(): string {
    let position = '';

    switch (this.dataModel.footerBtnPosition) {
      case 'right':
        position = 'justify-end';
        break;
      case 'left':
        position = 'justify-start';
        break;
      case 'center':
        position = 'justify-center';
        break;
      default:
        position = 'justify-end';
        break;
    }

    return position;
  }

}
