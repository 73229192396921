export class BaseModalModel {
  show = false;
  headerText = '';
  bodyText = '';
  cancelText = '';
  yesText = '';
  cancelOnly = false;
  footerBtnPosition = '';
  hideCancelBtn = false;
  hideYesBtn = false;
}
