import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GelToastService } from '@engagis.gel/theme';
import { Result } from 'src/app/data/data-ei/models/result.model';

@Injectable({
  providedIn: 'root'
})
export class PromptToastService {

  constructor(private toastService: GelToastService) { }

  // for response error toast message
  responseErrorToast(err: HttpErrorResponse): void {
    let message = 'Something went wrong. Please try again.';
    const error = err.error as Result<unknown>;

    if(err instanceof HttpErrorResponse && error?.errorMessage) {
      message = error.errorMessage;
    } else if(err instanceof HttpErrorResponse && err.message) {
      message = err.message;
    } else if(err?.statusText) {
      message = `${err.statusText}. Please try again later.`;
    }

    this.toastService.danger({
      title: 'Warning',
      message,
      actionText: 'Trigger',
    });
  }

  // for success toast message
  successToast(message: string): void {
    this.toastService.success({
      title: 'Successful',
      message,
      actionText: 'Trigger',
    });
  }

  // for error toast message
  errorToast(message: string): void {
    this.toastService.danger({
      title: 'Warning',
      message,
      actionText: 'Trigger',
    });
  }

  // for restore taost in bin
  restoreToast(type: string, length: number): void {
    this.toastService.success({
      title: 'Successful',
      message: `Restored ${length} ${type}${length > 1 ? 's' : ''} from the bin.`,
      actionText: 'Trigger',
    });
  }
}
