import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { interval } from 'rxjs/internal/observable/interval';
import { AuthService } from 'src/app/core/service/auth.service';

@Component({
  selector: 'app-error-unauthorized',
  templateUrl: './error-unauthorized.component.html',
  styleUrls: ['./error-unauthorized.component.css']
})
export class ErrorUnauthorizedComponent implements OnInit {
  // configUrl = environment;
  loginUrl = environment.portalLink;
  countdown = 10;

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.countdown = 10;
    let interval = setInterval(() => {
      this.countdown -= 1;
      if (this.countdown === 0) {
        location.href = environment.portalLink;
        clearInterval(interval);
        this.authService.logout();
      }
    }, 1000);
  }

}