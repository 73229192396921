import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { SystemNotification } from 'src/app/data/data-ei/models/messaging/system-notification.model';

@Component({
  selector: 'app-notifications-popover',
  templateUrl: './notifications-popover.component.html',
  styleUrls: ['./notifications-popover.component.css']
})
export class NotificationsPopoverComponent implements OnDestroy {
  @Input() unseenMessagesCount = 0;
  @Input() systemNotifications: SystemNotification[] = [];
  @Input() loading = false;
  @Input() maxSystemNotifications = 5;

  @Output() openMessages: EventEmitter<void> = new EventEmitter<void>();
  @Output() scrollEnd: EventEmitter<void> = new EventEmitter<void>();
  @Output() readNotification: EventEmitter<SystemNotification> =
    new EventEmitter<SystemNotification>();

  @Output() popoverClose: EventEmitter<void> = new EventEmitter<void>();

  get displayedSystemNotifications(): SystemNotification[] {
    return [];
    // return take(this.systemNotifications, this.maxSystemNotifications);
  }

  onScroll(event: Event): void {
    const target = event.target as HTMLDivElement;
    const scrollEnd =
      target.offsetHeight + target.scrollTop >= target.scrollHeight;
    if (scrollEnd) {
      this.scrollEnd.emit();
    }
  }

  ngOnDestroy(): void {
    this.popoverClose.emit();
  }
}
