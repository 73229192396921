<gel-modal
  [show]="dataModel.show"
  [width]="500"
  [withCloseButton]="false"
>
  <gel-modal-body>
    <div class="gel__text-center">
      <h2>
        <span>{{ dataModel.headerText }}</span>
      </h2>
      <p [innerHTML]="dataModel.bodyText"></p>
    </div>
  </gel-modal-body>
  <gel-modal-footer
    class="gel__layout-grid align-center gap-xs"
    [ngClass]="getFooterPosition()"
  >
    <button
      *ngIf="!dataModel.hideCancelBtn"
      class="mr1 btn-footer"
      nbButton 
      outline 
      status="primary"
      (click)="onCancel()"
    >
      {{ dataModel.cancelText }}
    </button>
    <button
      *ngIf="!dataModel.hideYesBtn"
      class="btn-footer"
      nbButton 
      status="primary"
      (click)="onAccept()"
    >
      {{ dataModel.yesText }}
    </button>
  </gel-modal-footer>
</gel-modal>