import { Component, ViewChild, TemplateRef } from '@angular/core';
import { AuthService } from 'src/app/core/service/auth.service';
import { UserInfo } from '../../models/user-info.model';
import { environment } from 'src/environments/environment';
import { MessagingService } from 'src/app/data/data-ei/service/messaging.service';
import { SystemNotification } from 'src/app/data/data-ei/models/messaging/system-notification.model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {
  @ViewChild('descriptionTemplate', { static: true}) descriptionTemplate: TemplateRef<any>;

  userDescription: string[] = [];
  userRoles = {
    shown: '',
    hidden: '',
    hiddenLength: 0
  };
  maxDisplayedNotifications = 5;
  notificationsLoading = false;

  private settingtoSeenIds: string[] = [];

  get isStaging(): boolean {
    return ['dev', 'staging'].includes(environment.envstr);
  }

  get unseenMessagesCount(): number {
    return this.messagingService.unseenMessagesCount;
  }

  get totalNotificationsCount(): number {
    return this.messagingService.totalNotificationsCount;
  }

  get systemNotifications(): SystemNotification[] {
    return this.messagingService.systemNotifications;
  }

  get userInfo(): UserInfo {
    let userInfo = this.authService.UserInfo;

    if(userInfo?.description) {
      const userDescription = userInfo.description.split(', ');

      if(userDescription.length > 2) {
        const shown = userDescription.filter((val, index) => index <= 1);
        const hidden = userDescription.filter((val, index) => index >= 2);

        this.userRoles = {
          shown: shown.join(', '),
          hidden: hidden.join(', '),
          hiddenLength: hidden.length
        };

        userInfo = {
          ...userInfo,
          descriptionTemplate: this.descriptionTemplate
        };
      }
    }

    return userInfo ? userInfo : {};
  }

  constructor(
    private messagingService: MessagingService,
    private authService: AuthService
  ) { }

  openMessages(): void {
    this.messagingService.open();
    // close notifications popover;
    document.body.click();
  }

  async onNotificationScrollEnd(): Promise<void> {
    if (this.maxDisplayedNotifications >= this.systemNotifications.length) {
      return;
    }

    this.notificationsLoading = true;
    await new Promise((resolve) => setTimeout(resolve, 2000));
    this.notificationsLoading = false;
    this.maxDisplayedNotifications += 5;
  }

  async readNotification(event: SystemNotification): Promise<void> {
    const proceed = !event.isSeen && !this.settingtoSeenIds.includes(event.id);
    if (!proceed) {
      return;
    }

    this.settingtoSeenIds.push(event.id);
    // Wait for 5 seconds before setting the notification as seen
    await new Promise((resolve) => setTimeout(resolve, 5000));
    try {
      await this.messagingService.setMessageAsSeen(event.id);
      this.messagingService.messages.find(
        (m) => m.messageId === event.id
      ).isSeen = true;
    } catch (error) {
      console.log(error);
    }
    this.settingtoSeenIds = this.settingtoSeenIds.filter(
      (id) => id !== event.id
    );
  }

  onLogout(): void {
    this.authService.logout('true');
    window.location.href = `${environment.portalBaseUrl}/Account/LogOff`;
  }

  notificationsClose(): void {
    this.maxDisplayedNotifications = 5;
  }

  get isDisabledNavigate(): boolean {
    return (this.userInfo.name === '');
  }
}
