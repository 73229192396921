<nb-layout-header fixed>
  <img class="mx2" src="assets/images/engagis-logo.png">
  <div class="gel__nav-menu">
    <gel-menu>
      <gel-menu-item [class.disabled]="isDisabledNavigate" routerLink="/dashboard">
        <span class="gel__nav-menu-item">Dashboard</span>
      </gel-menu-item>
      <gel-menu-item [class.disabled]="isDisabledNavigate" routerLink="/media-asset">
        <span
          parentRoute="media-asset"
          class="gel__nav-menu-item"
        >
          Media Assets
        </span>
      </gel-menu-item>
      <gel-menu-item [class.disabled]="isDisabledNavigate" routerLink="/playlist">
        <span
          parentRoute="playlist"
          class="gel__nav-menu-item"
        >
          Playlists
        </span>
      </gel-menu-item>
      <gel-menu-item [class.disabled]="isDisabledNavigate" routerLink="/screen-layout">
        <span
          parentRoute="screen-layout"
          class="gel__nav-menu-item"
        >
          Screen Layouts
        </span>
      </gel-menu-item>
      <gel-menu-item [class.disabled]="isDisabledNavigate" routerLink="/schedule">
        <span
          parentRoute="schedule"
          class="gel__nav-menu-item"
        >
          Schedules
        </span>
      </gel-menu-item>
      <gel-menu-item [class.disabled]="isDisabledNavigate" routerLink="/player">
        <span
          parentRoute="players"
          class="gel__nav-menu-item"
        >
          Players
        </span>
      </gel-menu-item>
      <gel-menu-item [class.disabled]="isDisabledNavigate" routerLink="/settings">
        <span
          parentRoute="settings"
          class="gel__nav-menu-item"
        >
          Settings
        </span>
      </gel-menu-item>
    </gel-menu>
  </div>
  <gel-app-picker 
    class="mx2"
    icon="eze-impress"
    [class.disabled]="isDisabledNavigate"
    [user]="userInfo" 
    [showNotifications]="isStaging && !isDisabledNavigate"
    [notificationsTemplate]="notifications"
    [notificationsCount]="totalNotificationsCount"
  >
    <ng-template #descriptionTemplate>
      {{ userRoles.shown }}
      <gel-chip
        class="chip user-roles-container"
      >
        +{{ userRoles.hiddenLength }}

        <span class="user-roles">
          {{ userRoles.hidden }}
        </span>
      </gel-chip>
    </ng-template>
    <gel-app-picker-item icon="eze-impress" routerLink="/">
      <span>Eze Impress</span>
    </gel-app-picker-item>
    <gel-app-picker-item (click)="onLogout()" logout></gel-app-picker-item>
  </gel-app-picker>

  <ng-template #notifications>
    <app-notifications-popover
      (openMessages)="openMessages()"
      (scrollEnd)="onNotificationScrollEnd()"
      [loading]="notificationsLoading"
      [maxSystemNotifications]="maxDisplayedNotifications"
      (readNotification)="readNotification($event)"
      [unseenMessagesCount]="unseenMessagesCount"
      [systemNotifications]="systemNotifications"
      (popoverClose)="notificationsClose()"
    >
    </app-notifications-popover>
  </ng-template>
</nb-layout-header>

<app-messaging
  *ngIf="isStaging && !isDisabledNavigate"
></app-messaging>
  