/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { GelMention, GelMentionService } from '@engagis.gel/theme';
import { interval } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { Conversation } from 'src/app/data/data-ei/models/messaging/conversation.model';
import { GelMessage } from 'src/app/data/data-ei/models/messaging/gel-message.model';
import { Message } from 'src/app/data/data-ei/models/messaging/message.model';
import { MessagingUser } from 'src/app/data/data-ei/models/messaging/messaging-user.model';
import { NewMessagePayload } from 'src/app/data/data-ei/models/messaging/new-message-payload.model';
import { ReplyMessagePayload } from 'src/app/data/data-ei/models/messaging/reply-message-payload.model';
import { FilterGroupPayload } from 'src/app/data/data-ei/models/payloads/filter-group-payload.model';
import { SearchPayload } from 'src/app/data/data-ei/models/payloads/search-payload.model';
import { Result } from 'src/app/data/data-ei/models/result.model';
import { SearchResult } from 'src/app/data/data-ei/models/search-result.model';
import { User } from 'src/app/data/data-ei/models/users/user.model';
import { MessagingService } from 'src/app/data/data-ei/service/messaging.service';
import { UserService } from 'src/app/data/data-ei/service/user.service';
import { PromptModalModel } from '../../models/modals/prompt-modal.model';
import { PromptToastService } from '../../services/prompt/prompt-toast.service';

interface Recipient {
  id: string;
  name: string;
}
interface MessageForm {
  subject: string;
  recipients: string[];
}

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.css']
})
export class MessagingComponent implements OnInit {
  refresh$ = interval(10000);

  isLoading = false;
  isSending = false;
  isLoadingThread = false;
  isRefreshing = false;
  addNew = false;
  selectedConversation: Conversation = null;
  selectedConversationRecipients: MessagingUser[] = [];

  addForm: MessageForm = {
    subject: '',
    recipients: [],
  };

  taggedUserIds: string[] = [];
  conversations: Conversation[] = [];

  currentUser: User;
  users: User[] = [];
  allRecipients: Recipient[] = [];
  recipients: Recipient[] = [];
  searchUserKey = '';
  isSearchingRecipients = false;
  deleteModal: PromptModalModel<Conversation> = new PromptModalModel({
    bodyText: '',
    yesText: 'Delete',
    headerText: 'Are you sure you want to delete this message?'
  });

  get isShown(): boolean {
    return this.messagingService.isOpen;
  }
  get sendDisabled(): boolean {
    const hasMessage = this.message.trim();
    if (this.addNew) {
      const hasRecipients =
        this.addForm.recipients.length || this.taggedUserIds.length;
      const hasSubject = this.addForm.subject.trim();
      return !hasMessage || !hasSubject || !hasRecipients;
    }
    return !hasMessage;
  }
  private _message = '';
  get message(): string {
    return this._message;
  }
  set message(value: string) {
    this.taggedUserIds = this.mentionService.extractUserIds(value);
    this._message = value;
  }

  // eslint-disable-next-line
  constructor(
    public mentionService: GelMentionService,
    private messagingService: MessagingService,
    private userService: UserService,
    private promptToastService: PromptToastService,
    private authService: AuthService
  ) { }

  async ngOnInit(): Promise<void> {
    this.currentUser = this.userService.getLoggedUser();
    if(this.currentUser) {
      this.currentUser.id = this.currentUser.id.toLocaleLowerCase();
      this.currentUser.name = this.authService?.UserInfo ? this.authService.UserInfo.name : '';
      await this.onSearchRecipients('');
      await this.loadInitialMessages();
    }
  }

  async loadInitialMessages(): Promise<void> {
    this.isLoading = true;
    await this.loadMessages();
    this.initializeAutoRefresh();
    this.isLoading = false;
  }

  initializeAutoRefresh(): void {
    // this.refresh$.subscribe(() => {
    //   const allowRefresh =
    //     !this.isSending && !this.isLoadingThread && !this.isRefreshing;
    //   if (allowRefresh) {
    //     void this.refreshMessages();
    //     if (this.selectedConversation && this.selectedConversation.id) {
    //       void this.loadMessageThread(this.selectedConversation.id);
    //     }
    //   }
    // });
  }

  async loadMessages(): Promise<void> {
    try {
      const payload: SearchPayload = {
        page: 1,
        pageSize: 200,
        direction: 'desc',
        orderBy: 'EventTime',
        searchTerm: '',
      };
      const { result } = await this.messagingService.getMessageThreads(payload);

      if(result) {
        this.messagingService.messages = [...result.searchResult];
        if(this.messagingService.messages.length > 0) {
          this.loadEmptyConversations();
        }
      }
    } catch (err) {
      this.promptToastService.responseErrorToast(err);
    }
  }

  loadEmptyConversations(): void {
    const messages = this.messagingService.messages.filter(
      (m) => m.type === 'Message'
    );
    const conversations: Conversation[] = [];
    for (const message of messages) {
      const subject = message.subject.trim()
        ? message.subject.trim()
        : '(No subject)';
      const dummyMessage = new GelMessage();
      dummyMessage.isRead = message.isSeen;

      let recipientNames = this.getUserName(message.sender);

      message.recipients.forEach(x => {
        if(x.userId !== message.sender.userId) {
          recipientNames += `, ${this.getUserName(x)}`;
        }
      });

      const conversation: Conversation = {
        id: message.messageId,
        subject,
        subheader: '',
        deletable: !(message.type === 'Comment'),
        recipient: recipientNames,
        messages: [dummyMessage],
        loading: false,
        isRead: message.isSeen,
      };

      if (
        this.selectedConversation &&
        this.selectedConversation.id === message.messageId
      ) {
        conversation.messages = [...this.selectedConversation.messages];
      }

      conversations.push(conversation);
    }
    this.conversations = conversations;
  }

  getUserName(user: MessagingUser | User): string {
    return user.firstName + ' ' + user.lastName;
  }

  async refreshMessages(): Promise<void> {
    try {
      this.isRefreshing = true;
      await this.loadMessages();
    } catch (error) {
      console.log(error);
    } finally {
      this.isRefreshing = false;
    }
  }

  async onGetUsers(filterGroups: FilterGroupPayload[] = []): Promise<User[]> {
    try {
      const payload = {
        page: 1,
        pageSize: 100,
        orderBy: '',
        direction: '',
        searchTerm: this.searchUserKey,
        isArchived: false,
        filterGroups,
      };
      const response = await this.userService.getUsers(payload);
      const totalPages = response.result.totalNumberOfPages;
      let users = response.result.searchResult;
      if (totalPages > 1) {
        const responseProms: Promise<Result<SearchResult<User>>>[] = [];
        for (let i = 2; i <= totalPages; i++) {
          payload.page = i;
          const responseProm = this.userService.getUsers(payload);
          responseProms.push(responseProm);
        }
        const responses = await Promise.all(responseProms);
        responses.forEach((r) => {
          users = [...users, ...r.result.searchResult];
        });
      }
      return users;
    } catch (err) {
      this.promptToastService.responseErrorToast(err);
    }
  }

  async loadMessageThread(messageId: string): Promise<void> {
    try {
      const response = await this.messagingService.getMessageThreadById(
        messageId
      );
      const thread = response.result;
      this.selectedConversationRecipients = thread.recipients;

      const message = this.messagingService.messages.find(
        (m) => m.messageId === messageId
      );

      const subject = message.subject.trim()
        ? message.subject.trim()
        : '(No subject)';
      const recipientNames = this.selectedConversation.recipient;

      const conversation: Conversation = {
        id: messageId,
        subject,
        subheader: '',
        deletable: !(message.type === 'Comment'),
        recipient: recipientNames,
        messages: [],
        loading: false,
        isRead: message.isSeen,
      };

      for (const reply of thread.replies) {
        const senderName = this.getUserName(reply.sender);
        const gelMessage: GelMessage = {
          message: reply.value,
          date: this.convertToLocalDate(reply.dateOfCreation),
          reply: reply.sender.userId === this.currentUser.id,
          type: 'text',
          user: { name: senderName },
          isRead: message.isSeen,
        };
        conversation.messages.push(gelMessage);
      }

      const index = this.conversations.findIndex((c) => c.id === messageId);
      this.conversations[index] = conversation;
      this.selectedConversation = this.conversations[index];

      if (!thread.isSeen) {
        void this.setMessageAsSeen(messageId);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async setMessageAsSeen(messageId: string): Promise<void> {
    await this.messagingService.setMessageAsSeen(messageId);
    const message = this.messagingService.messages.find(
      (m) => m.messageId === messageId
    );
    const conversation = this.conversations.find((c) => c.id === messageId);
    message.isSeen = true;
    conversation.messages.forEach((m) => {
      m.isRead = true;
    });
  }

  onDelete(value: Conversation): void {
    this.deleteModal.show = true;
    this.deleteModal.params = value;
  }

  async onSend(value: { conversation: Conversation; message: string }): Promise<void> {
    try {
      if (this.addNew) {
        const message = value.message.trim();
        const recipientIds = [...this.addForm.recipients];

        const subject = this.addForm.subject.trim();
        await this.onSendNewMessage(subject, message, recipientIds);
      } else {
        const messageId = value.conversation.id;
        const message = value.message;
        await this.sendReplyMessage(messageId, message);
      }
    } catch (err) {
      this.promptToastService.responseErrorToast(err);
    }
  }

  async sendReplyMessage(messageId: string, message: string): Promise<void> {
    const taggedUserIds = this.taggedUserIds;
    const currentRecipientIds = this.selectedConversationRecipients.map(
      (r) => r.userId.toLocaleLowerCase()
    );
    const messages = this.messagingService.messages;
    const newMessage = messages.find((m) => m.messageId === messageId);

    const originalRecipientIds = newMessage.recipients.map((r) => r.userId.toLocaleLowerCase());

    const recipients = [...new Set([
      ...originalRecipientIds,
      ...currentRecipientIds,
      ...taggedUserIds,
    ])];

    this.isSending = true;
    try {
      const payload: ReplyMessagePayload = {
        value: message,
        parentId: messageId,
        type: 'Message',
        recipients,
      };

      const response = await this.messagingService.sendReplyMessage(payload);
      const gelMessage: GelMessage = {
        message,
        date: response.timeGenerated,
        reply: true,
        type: 'text',
        user: { name: this.currentUser.name },
        isRead: true,
      };
      this.conversations
        .find((c) => c.id === messageId)
        .messages.push(gelMessage);

      const newRecipients = this.getRecipientsNameByIds(recipients);

      this.messagingService.messages = [
        {
          ...newMessage,
          recipients: newRecipients
        },
        ...messages.filter((m) => m.messageId !== messageId),
      ];

      let conversations: Conversation[] = [];
      this.conversations.forEach(x => {
        let row = {...x};
        if(row.id === messageId) {
          row = {
            ...row,
            recipient: newRecipients.map(rec => this.getUserName(rec)).join(', '),
          };
        }

        conversations = [
          ...conversations,
          row
        ];
      });

      this.conversations = conversations;

      // this.conversations = this.messagingService.messages
      //   .filter((m) => m.type === 'Message')
      //   .map((m) => this.conversations.find((c) => c.id === m.messageId));

      // this.selectedConversation = this.conversations.find(
      //   (c) => c.id === messageId
      // );
      setTimeout(() => {
        this.resetForm();
      });
    } catch (err) {
      this.promptToastService.responseErrorToast(err);
    } finally {
      this.isSending = false;
    }
  }

  async onSendNewMessage(
    subject: string,
    message: string,
    recipientIds: string[],
  ): Promise<void> {
    this.isSending = true;
    try {
      const recipients = [...new Set([...recipientIds, ...this.taggedUserIds])];

      const payload: NewMessagePayload = {
        subject,
        value: message,
        parentId: null,
        type: 'Message',
        recipients,
      };

      const response = await this.messagingService.sendNewMessage(payload);
      const messageId = response.result.messageId;

      const newResponse = await this.messagingService.getMessageThreadById(
        messageId
      );

      const sender: MessagingUser = {
        userId: this.currentUser.id,
        firstName: this.currentUser.firstName,
        lastName: this.currentUser.lastName,
      };

      const newRecipients = this.getRecipientsNameByIds(recipients);

      const newMessage: Message = {
        isSeen: newResponse.result.isSeen,
        messageId,
        recipients: newRecipients,
        sender,
        subject,
        type: 'Message',
      };
      const messages = this.messagingService.messages;
      this.messagingService.messages = [newMessage, ...messages];

      const newConversation: Conversation = {
        id: messageId,
        subject: subject.trim() ? subject.trim() : '(No subject)',
        subheader: '',
        deletable: !(newMessage.type === 'Comment'),
        recipient: newRecipients.map(x => this.getUserName(x)).join(', '),
        messages: [
          {
            message: newResponse.result.replies[0].value,
            date: this.convertToLocalDate(newResponse.result.replies[0].dateOfCreation),
            reply: true,
            type: 'text',
            user: { name: newResponse.result.sender },
            isRead: true,
          },
        ],
        loading: false,
        isRead: true,
      };
      this.conversations = [newConversation, ...this.conversations];
      void this.onSelect(this.conversations[0]);
    } catch (err) {
      this.promptToastService.responseErrorToast(err);
    } finally {
      this.isSending = false;
    }
  }

  getRecipientsNameByIds(recipients: string[]): MessagingUser[] {
    const sender: MessagingUser = {
      userId: this.currentUser.id,
      firstName: this.currentUser.firstName,
      lastName: this.currentUser.lastName,
    };

    const newRecipients: MessagingUser[] = [sender];

    for (const id of recipients) {
      if(sender.userId !== id) {
        const user = this.getUserById(id);
        const newRecipient: MessagingUser = {
          userId: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
        };
        newRecipients.push(newRecipient);
      }
    }

    return newRecipients;
  }

  getUserById(id: string): User {
    const user = this.users.find(x => x.id.toLocaleLowerCase() === id.toLocaleLowerCase());
    return user;
  }

  onSearch(event: string): GelMention[] {
    const searchTerm = event.trim().toString();

    const users = !searchTerm
      ? this.allRecipients
      : this.allRecipients.filter((x) => x.name.toLowerCase().includes(searchTerm.toLowerCase()));

    return users.map((user) => ({
      id: `@${user.name}`,
      userId: user.id,
      name: user.name,
    }));
  }

  async onSearchRecipients(event: string): Promise<void> {
    const searchTerm = event.trim();
    this.isSearchingRecipients = true;
    try {
      if (!this.allRecipients.length) {
        const users = await this.onGetUsers();
        this.users = users;
        this.allRecipients = users.map(x => {
          const id = x.id.toLocaleLowerCase();
          const currentUser = this.userService.getLoggedUser();

          if(currentUser.id.toLocaleLowerCase() === id) {
            this.currentUser = {
              ...x,
              ...this.currentUser,
            };
          }
          return {
            id,
            name: `${this.getUserName(x)}${(this.currentUser.id === id) ? ' (Myself)' : ''}`
          };
        });
      }
      if (!searchTerm) {
        this.recipients = [...this.allRecipients];
        return;
      }

      const recipients = this.allRecipients.filter((u) =>
        u.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      this.recipients = recipients;
    } catch (err) {
      this.promptToastService.responseErrorToast(err);
    } finally {
      this.isSearchingRecipients = false;
    }
  }

  onChangeRecipients(recipients: string[]): void {
    this.addForm = {
      ...this.addForm,
      recipients
    };
  }

  async onSearchCampaigns(event: string): Promise<void> {
    //
  }

  async onSelect(value: Conversation): Promise<void> {
    const currentMessageId = this.selectedConversation
      ? this.selectedConversation.id
      : '';
    if (this.isLoadingThread || currentMessageId === value.id) {
      return;
    }

    const messageId = value.id;

    this.selectedConversation = value;
    this.addNew = false;
    this.resetForm();

    this.isLoadingThread = true;
    await this.loadMessageThread(messageId);
    this.isLoadingThread = false;
  }

  resetForm(): void {
    this.addForm.subject = '';
    this.addForm.recipients = [];
    this.message = '';
  }

  onAdd(): void {
    this.addNew = true;
    this.resetForm();
    this.selectedConversation = null;
    this.selectedConversationRecipients = [];
  }

  closeMessages(): void {
    this.messagingService.close();
  }

  convertToLocalDate(date: string | Date): Date {
    const dateTime = new Date(date);
    const convertedDate = new Date(dateTime.getTime() - dateTime.getTimezoneOffset()*60*1000);

    return convertedDate;
  }

  async onCloseModal(value: string): Promise<void> {
    if (value === this.deleteModal.yesText) {
      const message = this.deleteModal.params;
      try {
        const messageId = message.id;
        message.loading = true;
        await this.messagingService.unsubscribeFromMessage(messageId);

        this.conversations = this.conversations.filter((c) => c.id !== messageId);
        this.messagingService.messages = this.messagingService.messages.filter(
          (m) => m.messageId !== messageId
        );

        const isSelectedMessageDeleted =
          this.selectedConversation && this.selectedConversation.id === messageId;
        if (isSelectedMessageDeleted) {
          this.selectedConversation = null;
        }

        this.addNew = false;
        this.resetForm();
      } catch (err) {
        message.loading = false;
        this.promptToastService.responseErrorToast(err);
      }
    }
  }

}
