import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromptModalComponent } from './prompt-modal.component';
import { GelModalModule, NbButtonModule, NbCardModule, NbIconModule } from '@engagis.gel/theme';



@NgModule({
  declarations: [
    PromptModalComponent
  ],
  imports: [
    CommonModule,
    NbCardModule,
    NbIconModule,
    NbButtonModule,
    GelModalModule
  ],
  exports: [
    PromptModalComponent
  ]
})
export class PromptModalModule { }
