<div class="popover-container" (scroll)="onScroll($event)">
  <nb-list>
    <nb-list-item class="cursor-pointer" (click)="openMessages.emit()">
      <div class="gel__layout-flex items-center">
        <nb-icon status="basic" appearanc="ghost" pack="eze-suite-icons" icon="notify" class="icon-notification pr-1">
        </nb-icon>
        <div *ngIf="unseenMessagesCount > 0">
          <span class="color-primary pr-1">{{ unseenMessagesCount }} unread</span>
          <span>{{ unseenMessagesCount === 1 ? ' message' : ' messages' }}</span>
        </div>
        <div *ngIf="unseenMessagesCount === 0">
          <span>Messages</span>
        </div>
      </div>
    </nb-list-item>
    <nb-list-item *ngFor="let notification of displayedSystemNotifications">
      <div class="gel__layout-flex items-center">
        <div>
          <nb-icon status="basic" appearanc="ghost" pack="eze-suite-icons" icon="calendar"
            class="icon-notification pr-1">
          </nb-icon>
        </div>
        <div [ngClass]="{ 'text-bold': !notification.isSeen }">
          <span class="color-primary pr-1">{{ notification.campaignName }}</span>
          <span>{{ notification.message }}</span>
        </div>
      </div>
      {{ readNotification.emit(notification) }}
    </nb-list-item>
    <nb-list-item *ngIf="loading && displayedSystemNotifications.length">
      <div class="gel__layout-flex justify-center items-center">
        <button nbButton spinner appearance="ghost" status="primary">Loading...</button>
      </div>
    </nb-list-item>
  </nb-list>
</div>