// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { format, parseISO } from 'date-fns';
import { ScheduleType } from 'src/app/data/data-ei/models/schedules/schedule-type.model';

export const environment = {
  envstr: 'dev',
  production: false,
  apiBaseUrl: 'https://engagisstaging.azure-api.net',
  versionId: 1,
  azureSubscriptionKey: 'dc39365def3441569499d7593ceee67a',
  portalBaseUrl: 'https://portal.engagisstaging.com',
  portalLink: 'https://portal.engagisstaging.com/Account/Login',
  loginUrl: 'https://engagisstaging.azure-api.net/oauth/token',
  mockApiBaseUrl: 'https://engagisstaging.azure-api.net',
  apiPath: '/ezeimp-refresh/v1.0',
  appCode: 'eir',
  pixabayBaseUrl: 'https://engagis.azure-api.net/pixabay',
  pixabaySubKey: 'f5dfe47f23ba48cc9ac0ad5f84d5297a',
  facebookAppId: '423333026041199',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  client_id: '03fb9044-d4df-40d5-ac76-dd8a18fe3032',
};

// global value
export const globals = {
  customAttributes: [
    {
      id: 'suggestive-text-device',
      attributeName: 'Device',
      attributeType: 'Device',
      formattedAttributeName: 'Players',
      controlType: 'suggestiveText',
      options: []
    }, {
      id: 'suggestive-text-location',
      attributeName: 'Location',
      attributeType: 'Location',
      formattedAttributeName: 'Location',
      controlType: 'suggestiveText',
      options: []
    }, {
      id: 'suggestive-text-player-tag',
      attributeName: 'Player tag',
      attributeType: 'Tag',
      formattedAttributeName: 'Player tag',
      controlType: 'suggestiveText',
      options: []
    }
  ],
  pageLimits: [10, 25, 50, 100],
  defaultPageSize: 25,
  assetTypes: [
    {
      value: 'all',
      label: 'All'
    }, {
      value: 'image',
      label: 'Image'
    }, {
      value: 'video',
      label: 'Video'
    }, {
      value: 'audio',
      label: 'Audio'
    },
    // {
    //   value: 'rss',
    //   label: 'RSS'
    // },
    {
      value: 'html_app',
      label: 'HTML Template'
    }, {
      value: 'iframe',
      label: 'iFrame'
    },
    // {
    //   value: 'ticker',
    //   label: 'Ticker'
    // }, {
    //   value: 'iptv',
    //   label: 'IPTV'
    // },
    {
      value: 'youtube',
      label: 'Youtube'
    },
    // {
    //   value: 'weather',
    //   label: 'Weather'
    // }, {
    //   value: 'twitter',
    //   label: 'Twitter'
    // }
  ],
  sortTypes: [
    'Last modified',
    'Name A - Z',
    'Name Z - A',
    'Size',
  ],
  states: [
    'NSW',
    'SA',
    'Vic',
  ],
  languages: [
    'English',
    'Chinese',
  ],
  pagination: {
    page: 1,
    start: 1,
    end: 25,
    total: 0,
    size: 25,
    orderBy: 'lastUpdatedAt',
    direction: 'desc',
  },
  cardSizes: [
    {
      title: 'Large',
      value: 'large',
      icon: 'grid',
      iconPack: 'eze-suite-icons',
    }, {
      title: 'Small',
      value: 'small',
      icon: 'grid',
      iconPack: 'eze-suite-icons',
    }, {
      title: 'List',
      value: 'list',
      icon: 'list',
      iconPack: 'eze-suite-icons',
    }
  ],
  uploadTimes: [
    {
      value: '24',
      label: '24 hours'
    },
  ],
  screenTypes: [
    /** START DONT REMOVE Commented Temporarily Post Release DONT REMOVE START */
    // {
    //   value: 'all',
    //   label: 'All types'
    // },
    {
      value: 'predefined',
      label: 'Predefined layouts'
    },
    // {
    //   value: 'custom',
    //   label: 'Custom layouts'
    // },
    /** END DONT REMOVE Commented Temporarily Post Release DONT REMOVE END */
  ],
  playlistTypes: [
    {
      value: 'Regular',
      label: 'Regular playlist'
    }, {
      value: 'Smart',
      label: 'Smart playlist'
    }, {
      value: 'Audio',
      label: 'Audio playlist'
    }
  ],
  scheduleTypes: [
    {
      value: ScheduleType.Regular,
      label: 'Regular schedules'
    }, {
      value: ScheduleType.Smart,
      label: 'Smart schedules'
    }, {
      value: ScheduleType.Audio,
      label: 'Audio schedules'
    },
  ],
  approvalStatus: [
    {
      value: -1,
      label: 'All'
    }, {
      value: 0,
      label: 'Approved'
    }, {
      value: 1,
      label: 'Not Approved'
    }, {
      value: 2,
      label: 'Unapproved'
    }
  ],
  publishingStatus: [
    {
      value: -1,
      label: 'All'
    }, {
      value: 0,
      label: 'Published'
    }, {
      value: 1,
      label: 'Not Published'
    }, {
      value: 2,
      label: 'Unpublished'
    }
  ],
  assetStatus: [
    {
      value: -1,
      label: 'All'
    }, {
      value: 0,
      label: 'Playing now'
    }, {
      value: 1,
      label: 'Expired'
    }, {
      value: 2,
      label: 'Scheduled'
    }
  ],
  datatableLoading: {
    status: false,
    message: 'Retrieving necessary details. Please wait for a moment.',
    type: 'spinner'
  },
  smartSched: {
    isActive: false,
    date: {
      start: parseISO(format(new Date(), 'yyyy-MM-dd')),
      end: parseISO(format(new Date(), 'yyyy-MM-dd'))
    },
    smartTag: null,
    events: [],
    locationsDevices: [],
    ranlocationsDevices: [],
    results: [],
    exclusions: [],
    slots: [],
    isIndefinite: false,
    excludedResults: [],
    completed: false,
    isFillerAsset: false,
  },
  widgets: {
    asset: [
      {
        id: 1,
        title: 'Assets by type',
        description: 'Eg. photos vs videos',
        imageSrc: 'assets/images/widgets/widget-assets-by-type.png',
      },
      {
        id: 2,
        title: 'Recently updated',
        description: 'List of recent assets',
        imageSrc: 'assets/images/widgets/widget-assets-recently-modified.png',
      },
      {
        id: 3,
        title: 'Assets by users',
        description: 'Uploads breakdown by user',
        imageSrc: 'assets/images/widgets/widget-assets-by-user.png',
      },
    ],
    playlist: [
      {
        id: 4,
        title: 'Playlists overview',
        description: 'Active vs inactive',
        imageSrc: 'assets/images/widgets/widget-playlists-overview.png',
      },
      {
        id: 5,
        title: 'Playlists by users',
        description: 'Playlist breakdown by user',
        imageSrc: 'assets/images/widgets/widget-playlist-by-user.png',
      },
      {
        id: 6,
        title: 'Smart playlist planned',
        description: 'Distribution breakdown',
        imageSrc: 'assets/images/widgets/widget-playlist-planned.png',
      },
      {
        id: 7,
        title: 'Smart playlist historical',
        description: 'Distribution breakdown',
        imageSrc: 'assets/images/widgets/widget-playlist-historical.png',
      },
    ],
    schedule: [
      {
        id: 8,
        title: 'Schedules',
        description: 'Active vs expiring or expired',
        imageSrc: 'assets/images/widgets/widget-schedules-overview.png',
      },
      {
        id: 9,
        title: 'Schedules by users',
        description: 'Schedule breakdown by user',
        imageSrc: 'assets/images/widgets/widget-schedules-by-user.png',
      },
    ],
    players: [
      {
        id: 10,
        title: 'Player status',
        description: 'Online vs offline',
        imageSrc: 'assets/images/widgets/widget-player-status.png',
      },
      {
        id: 11,
        title: 'Content status',
        description: 'Up to date vs needs update',
        imageSrc: 'assets/images/widgets/widget-content-status.png',
      },
      {
        id: 12,
        title: 'Real time heartbeat',
        description: 'Device healthcheck',
        imageSrc: 'assets/images/widgets/widget-heartbeat.png',
      },
      {
        id: 13,
        title: 'Players overview',
        description: 'List of players',
        imageSrc: 'assets/images/widgets/widget-players-overview.png',
      },
    ],
    general: [
      {
        id: 14,
        title: 'Users overview',
        description: 'Active vs inactive',
        imageSrc: 'assets/images/widgets/widget-user-status.png',
      },
      {
        id: 15,
        title: 'Notifications',
        description: 'System activity',
        imageSrc: 'assets/images/widgets/widget-notifications.png',
      },
    ]
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
