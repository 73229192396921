import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagingComponent } from './messaging.component';
import { GelMessagingModule, NbButtonModule, NbInputModule, NbSelectModule, NbSpinnerModule } from '@engagis.gel/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PromptModalModule } from '../modals/prompt-modal/prompt-modal.module';



@NgModule({
  declarations: [MessagingComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GelMessagingModule,
    NbSelectModule,
    NbButtonModule,
    NbInputModule,
    NbSpinnerModule,
    PromptModalModule
  ],
  exports: [MessagingComponent],
})
export class MessagingModule { }
