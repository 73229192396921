import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthModel } from 'src/app/shared/services/modules/auth/auth.model';
import { DecodedToken } from 'src/app/shared/services/modules/auth/decoded-token.model';
import { environment } from 'src/environments/environment';
import { Result } from '../models/result.model';
import { SearchResult } from '../models/search-result.model';
import { UserSearchModel } from '../models/users/user-search.model';
import { User } from '../models/users/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  baseUrl = `${environment.apiBaseUrl}${environment.apiPath}/User`;
  versionId = 1;

  constructor(
    private http: HttpClient
  ) {}

  getUsers(param: UserSearchModel): Promise<Result<SearchResult<User>>> {
    const url = `${this.baseUrl}/search`;
    return this.http.post<Result<SearchResult<User>>>(url, param).toPromise();
  }

  getLoggedUser(): User {
    if (sessionStorage.getItem('access_token') === null) {
      return null;
    }
    const decodedToken = JSON.parse(
      atob(
        (
          JSON.parse(sessionStorage.getItem('access_token')) as AuthModel
        ).access_token.split('.')[1]
      )
    ) as DecodedToken;

    return {
      id: decodedToken.sub,
      email: decodedToken['ezesuite:user:email'],
    };
  }
}
