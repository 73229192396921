import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { interval } from 'rxjs';

@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.css'],
})
export class SessionExpiredComponent implements OnInit {
  loginUrl = environment.portalLink;
  countdown = 10;

  ngOnInit(): void {
    interval(1000).subscribe(() => {
      if (this.countdown !== 0) {
        this.countdown -= 1;
      }

      if (this.countdown === 0) {
        location.href = environment.portalLink;
      }
    });
  }
}
