/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthModel } from 'src/app/shared/services/modules/auth/auth.model';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import { Location } from '@angular/common';

interface RequestError {
  status: number;
  statusText: string;
  error?: {
    errorMessage: string;
  };
}

@Injectable()
export class AppInterceptorService implements HttpInterceptor {
  constructor(
    public loc: Location,
    private router: Router,
    private authService: AuthService
  ){}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const pixabayCondition = req.url.includes(environment.pixabayBaseUrl);

    const subKey = pixabayCondition
      ? environment.pixabaySubKey
      : environment.azureSubscriptionKey;

    let request = req.clone({
      headers: req.headers
        .set('Ocp-Apim-Subscription-Key', subKey)
    });

    const token = JSON.parse(sessionStorage.getItem('access_token')) as AuthModel;

    if (token && this.authService.isAccessTokenValid(token.access_token) && !pixabayCondition) {
      request = this.clone(request, token.access_token);
    }
    // this.sendError('400', 'Bad Request');
    // return;
    return next.handle(request).pipe(
      catchError((error: RequestError) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401 || error.status === 400) {
            if(!sessionStorage.getItem('prevUrl')) {
              sessionStorage.setItem('prevUrl', this.router.url);
            }
            if (token && token.access_token && token.refresh_token) {
              return this.authService.getRefreshToken(token.refresh_token)
                .pipe(
                  switchMap((data: AuthModel) => {
                    const param = {
                      ...token,
                      access_token: data.token,
                      refresh_token: data.refresh_token
                    };
                    sessionStorage.setItem('access_token', JSON.stringify(param));
                    return next.handle(this.clone(request, param.access_token));
                  })
                );
            } else {
              window.location.href = this.authService.buildLoginUrl();
              return;
            }
          }
          return throwError(error);

          // switch (error.status) {
          //   case 404:
          //     return throwError(error);
          //   case 500:
          //     this.sendError('500', 'Internal Server Error');
          //     return throwError(error);
          //   // case 502:
          //   //   this.sendError('502', 'Bad Gateway');
          //   //   return throwError(error);
          //   case 503:
          //     this.sendError('503', 'Service Unavailable Server Error');
          //     return throwError(error);
          //   case 504:
          //     this.sendError('504', 'Gateway Timeout Error');
          //     return throwError(error);
          //   default:
          //     return throwError(error);
          // }
        } else {
          return throwError(error);
        }
      }));
  }

  sendError(errorStatus: string, errorText: string): void {
    const value = {
      previousRoute: this.loc.path(),
      errorStatus,
      errorText
    };
    void this.router.navigate(['error-request'], { queryParams: { data: JSON.stringify(value) } });
  }

  private clone(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
        url: request.url,
        headers: request.headers
          .set('Authorization', `Bearer ${token}`)
      });
  }
}
