import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NbThemeModule,
  NbLayoutModule,
  GelMenuModule,
  GelAppPickerModule,
  NbCheckboxModule,
  GelToastModule,
  NbDatepickerModule,
  NbCardModule,
  NbIconModule,
  NbButtonModule,
  GelInputGroupModule,
  GelChipModule,
  NbTimepickerModule,
  NbListModule
} from '@engagis.gel/theme';
import { NbEvaIconsModule } from '@engagis.gel/eva-icons';
import { NavbarComponent } from './shared/component/navbar/navbar.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UsageDetailsModalComponent } from './shared/component/usage/usage-details-modal/usage-details-modal.component';
import { NgxDatatableModule } from '@engagis.gel/datagrid';
import { NbDateFnsDateModule } from '@engagis.gel/date-fns';
import { PromptModalComponent } from './shared/component/modals/prompt-modal/prompt-modal.component';
import { TrashModalComponent } from './shared/component/modals/trash-modal/trash-modal.component';
import { PreviewPlaylistModalComponent } from './shared/component/modals/preview-playlist-modal/preview-playlist-modal.component';
import { SchedulePublishModalComponent } from './shared/component/modals/schedule-publish-modal/schedule-publish-modal.component';
import { ResolutionPromptModalComponent } from './shared/component/modals/resolution-prompt-modal/resolution-prompt-modal.component';
import {
  SdSaveFilterModalComponent
} from './shared/component/set-smart-schedule/sd-locations-devices/sd-save-filter-modal/sd-save-filter-modal.component';
import { PlayerAssignedModalComponent } from './shared/component/modals/player-assigned-modal/player-assigned-modal.component';
import { AssetListModalComponent } from './shared/component/modals/asset-list-modal/asset-list-modal.component';
import { AssetReplacingTagModalComponent } from './shared/component/modals/asset-replacing-tag-modal/asset-replacing-tag-modal.component';
import { AppInterceptorService } from './core/interceptor/app-interceptor.service';
import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { ErrorUnauthorizedComponent } from './shared/component/errors/error-unauthorized/error-unauthorized.component';
import { MessagingModule } from './shared/component/messaging/messaging.module';
import { NotificationsPopoverComponent } from './shared/component/navbar/components/notifications-popover/notifications-popover.component';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    ErrorUnauthorizedComponent,
    NotificationsPopoverComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    // gel
    BrowserAnimationsModule,
    BrowserModule,
    NbThemeModule.forRoot({ name: 'gel-blue' }),
    NbLayoutModule,
    NbCardModule,
    NbIconModule,
    NbButtonModule,
    NgxDatatableModule,
    NbTimepickerModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDateFnsDateModule.forRoot({
      parseOptions: { useAdditionalWeekYearTokens: true, useAdditionalDayOfYearTokens: true },
      formatOptions: { useAdditionalWeekYearTokens: true, useAdditionalDayOfYearTokens: true },
    }),
    GelToastModule.forRoot(),
    NbEvaIconsModule,
    NbCheckboxModule,
    GelInputGroupModule,
    GelMenuModule,
    GelChipModule,
    GelAppPickerModule,
    NbListModule,
    MessagingModule,
  ],
  entryComponents: [
    UsageDetailsModalComponent,
    PromptModalComponent,
    TrashModalComponent,
    PreviewPlaylistModalComponent,
    SchedulePublishModalComponent,
    ResolutionPromptModalComponent,
    SdSaveFilterModalComponent,
    PlayerAssignedModalComponent,
    AssetListModalComponent,
    AssetReplacingTagModalComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptorService,
      multi: true
    },
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseHref,
      deps: [PlatformLocation]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


