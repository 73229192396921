import { BaseModalParamModel } from './base-modal-param.model';
import { BaseModalModel } from './base-modal.model';

export class PromptModalModel<T> extends BaseModalModel {
  params: T;
  constructor(param?: BaseModalParamModel) {
    super();
    this.bodyText = param?.bodyText;
    this.cancelOnly = param?.cancelOnly;
    this.cancelText = param?.cancelText ?? 'Cancel';
    this.headerText = param?.headerText;
    this.yesText = param?.yesText ?? 'OK';
    this.footerBtnPosition = param?.footerBtnPosition ?? 'right';
    if(param?.hideCancelBtn) {this.hideCancelBtn = param?.hideCancelBtn;}
    if(param?.hideYesBtn) {this.hideYesBtn = param?.hideYesBtn;}
  }
}
