import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorForbiddenComponent } from './shared/component/errors/error-forbidden/error-forbidden.component';
import { ErrorRequestComponent } from './shared/component/errors/error-request/error-request.component';
import { ErrorUnauthorizedComponent } from './shared/component/errors/error-unauthorized/error-unauthorized.component';
import { SessionExpiredComponent } from './shared/component/errors/session-expired/session-expired.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(r => r.DashboardModule)
  },
  {
    path: 'media-asset',
    loadChildren: () => import('./modules/media-assets/media-assets.module').then(r => r.MediaAssetsModule)
  },
  {
    path: 'player',
    loadChildren: () => import('./modules/players/players.module').then(r => r.PlayersModule)
  },
  {
    path: 'playlist',
    loadChildren: () => import('./modules/playlists/playlists.module').then(r => r.PlaylistsModule)
  },
  {
    path: 'schedule',
    loadChildren: () => import('./modules/schedules/schedules.module').then(r => r.SchedulesModule)
  },
  {
    path: 'screen-layout',
    loadChildren: () => import('./modules/screens/screens.module').then(r => r.ScreensModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/settings/settings.module').then(r => r.SettingsModule)
  },
  // {
  //   path: 'tags',
  //   loadChildren: () => import('./modules/tags/tags.module').then(r => r.TagsModule)
  // },
  {
    path: 'unauthorized',
    component: ErrorUnauthorizedComponent,
  },
  {
    path: 'session-expired',
    component: SessionExpiredComponent,
  },
  {
    path: 'error-request',
    component: ErrorRequestComponent,
  },
  {
    path: 'error-forbidden',
    component: ErrorForbiddenComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
